body {
  margin: 0;
  /*font-family: "Playfair Display", sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width:100%;
  height: 100%;
}

code {
  /*font-family: "Playfair Display", sans-serif;*/
}
